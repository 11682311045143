e4.cart = {
    init: function () {
        if ( jQuery( '.js-e-cart-form' ).length === 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerHelpers( 'loop compare' );
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-cart-orderlines #js-e-handlebars-tmpl-cart-summery' );
                e4.cart.event = e4.util.registerEvents( 'load loaded add added update updated', 'cart' );
                e4.cart.bindEvents();
                e4.cart.orderline.bindEvents();
                e4.cart.load();
            } );
        }
        else if ( jQuery( '.js-e-cart-quantity' ).length > 0 ) {
            e4.cart.updateQuantity();
            e4.cart.msg.init();
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-cart-form' ).on( 'submit', function ( event ) {
            event.preventDefault();
        } );
    },
    ajax: function ( objAjaxSettings, fnCallback ) {
        var jqXHR = jQuery.ajax( jQuery.extend( {
            url: e4.settings.ecom.cart.url,
            method: 'POST',
            dataType: 'json',
            cache: false
        }, objAjaxSettings ) );
        jqXHR.done( function ( data ) {
            if ( typeof fnCallback === 'function' ) {
                fnCallback( data );
            }
        } );
    },
    load: function () {
        var jCart = jQuery( '.js-e-cart' );
        jCart.trigger( e4.cart.event.load );
        e4.cart.ajax( {
            method: 'GET',
            beforeSend: function () {
                jCart.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
            }
        }, function ( data ) {
            e4.cart.update( data );
            jCart.trigger( e4.cart.event.loaded );
        } );
    },
    addProduct: function ( strObjProduct, fnCallback ) {
        var jCart = jQuery( '.js-e-cart' );
        jCart.trigger( e4.cart.event.add );
        var objProductData = {
            CartCmd: 'add',
            ProductID: '',
            VariantID: '',
            Quantity: 1,
            Redirect: false
        };
        if ( jQuery.isPlainObject( strObjProduct ) ) {
            jQuery.extend( objProductData, strObjProduct );
        }
        else if ( jQuery.type( strObjProduct ) === 'string' ) {
            objProductData.ProductID = strObjProduct;
        }
        if ( objProductData.ProductID.indexOf( 'PROD' ) === 0 ) {
            e4.cart.ajax( {
                data: objProductData,
                beforeSend: function () {
                    var jCart = jQuery( '.js-e-cart' );
                    if ( jCart.length === 1 ) {
                        jCart.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
                    }
                }
            }, function ( data ) {
                if ( jCart.length === 1 ) {
                    e4.cart.update( data );
                    jCart.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
                    e4.cart.orderline.focus( data.cart.lastAdded[ 0 ].id );
                }
                else {
                    e4.cart.msg.show( data );
                }
                e4.cart.updateQuantity( data.cart.totalProductQuantity );
                if ( typeof fnCallback === 'function' ) {
                    fnCallback( data );
                }
                jCart.trigger( e4.cart.event.added );
            } );
        }
    },
    update: function ( data ) {
        var jCart = jQuery( '.js-e-cart' );
        jCart.trigger( e4.cart.event.update );
        jQuery( '.js-e-cart-orderlines' ).html( e4.handlebars.tmpl.cartOrderlines( data ) );
        e4.loading.resolveWait( '.js-e-cart-orderlines' );
        jQuery( '.js-e-cart-summery' ).html( e4.handlebars.tmpl.cartSummery( data ) );
        e4.loading.resolveWait( '.js-e-cart-summery' );
        e4.cart.updateQuantity( data.cart.totalProductQuantity );
        jCart.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
        jCart.trigger( e4.cart.event.updated );
    },
    updateQuantity: function ( intQuantity ) {
        if ( intQuantity === 0 ) {
            jQuery( '.js-e-cart-quantity' ).html( '' );
        }
        else if ( isNaN( parseInt( intQuantity ) ) === true ) {
            e4.cart.ajax( {
                method: 'GET'
            }, function ( data ) {
                e4.cart.updateQuantity( data.cart.totalProductQuantity );
            } );
        }
        else {
            jQuery( '.js-e-cart-quantity' ).html( intQuantity );
        }
    },
    orderline: {
        init: function () {
            // e4.cart.orderline.event = e4.util.registerEvents( 'add added update updated delete deleted', 'cart' );
            e4.cart.orderline.bindEvents();
        },
        bindEvents: function () {
            e4.cart.updateOrderlineTimeout = null;
            jQuery( '.js-e-cart-form' ).on( 'focus blur', '.js-e-orderline-quantity', function ( event ) {
                var jOrderline = jQuery( event.target ).closest( '.js-e-orderline' );
                if ( event.type === 'focusin' ) {
                    jQuery( event.target ).select();
                    jOrderline.addClass( 'has-focus' );
                }
                else {
                    jOrderline.removeClass( 'has-focus' );
                }
            } ).on( 'input', '.js-e-orderline-quantity', function ( event ) {
                if ( e4.cart.updateOrderlineTimeout !== null ) {
                    clearTimeout( e4.cart.updateOrderlineTimeout );
                    e4.cart.updateOrderlineTimeout = null;
                }
                e4.cart.updateOrderlineTimeout = setTimeout( function () {
                    if ( parseInt( event.target.value ) !== 0 && event.target.value !== '' ) {
                        e4.cart.orderline.update( event.target );
                    }
                }, e4.settings.ecom.cart.updateOrderlineDelay );
            } ).on( 'click', '.js-e-orderline-update', function ( event ) {
                event.preventDefault();
                e4.cart.orderline.update( this );
            } ).on( 'click', '.js-e-orderline-delete', function ( event ) {
                event.preventDefault();
                e4.cart.orderline.delete( this );
            } );
        },
        update: function ( domElm ) {
            var jCart                = jQuery( '.js-e-cart' ),
                jForm                = jQuery( domElm ).closest( '.js-e-cart-form' ),
                jOrderline           = jQuery( domElm ).closest( '.js-e-orderline' ),
                intOrderlineQuantity = parseInt( jOrderline.find( '.js-e-orderline-quantity' ).val() );
            if ( intOrderlineQuantity !== 0 ) {
                jForm.find( '.js-e-cart-form-cartcmd' ).val( 'updateorderlines' );
                e4.cart.ajax( {
                    data: jForm.serialize(),
                    beforeSend: function () {
                        jCart.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
                    }
                }, function ( data ) {
                    e4.cart.update( data );
                    jCart.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
                    e4.cart.orderline.focus( jOrderline.attr( 'id' ) );
                } );
            }
            else {
                e4.cart.orderline.delete( domElm );
            }
        },
        delete: function ( domElm ) {
            e4.cart.ajax( {
                data: {
                    key: jQuery( domElm ).closest( '.js-e-orderline' ).attr( 'id' ),
                    CartCmd: 'delorderline',
                    Redirect: false
                }
            }, function ( data ) {
                e4.cart.update( data );
            } );
        },
        focus: function ( strIdentifier ) {
            var jOrderline = jQuery( '.js-e-orderline' ).filter( '#' + strIdentifier + ',[data-product="' + strIdentifier + '"]' );
            if ( jOrderline.length === 1 ) {
                jOrderline.find( '.js-e-orderline-quantity' ).focus();
            }
        }
    },
    msg: {
        timeout: null,
        init: function () {
            if ( jQuery( '.js-e-product-form' ).length > 1 ) {
                e4.util.require.js( e4.settings.require.handlebars, function () {
                    e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-cart-msg-lastadded' );
                } );
                e4.cart.msg.bindEvents();
            }
        },
        bindEvents: function () {
            jQuery( '#js-e-cart-msg' ).on( 'show.bs.modal', function () {
                jQuery( 'body' ).addClass( 'modal-open-no-adjustment' );
            } ).on( 'hidden.bs.modal', function () {
                jQuery( 'body' ).removeClass( 'modal-open-no-adjustment' );
            } ).on( 'click', function ( event ) {
                if ( jQuery( event.target ).is( '#js-e-cart-msg' ) === true ) {
                    e4.cart.msg.hide();
                }
            } ).find( '.modal-dialog' ).on( 'mouseover', e4.cart.msg.clearTimeout ).on( 'mouseleave', e4.cart.msg.setTimeout );
        },
        show: function ( data ) {
            var jCartMsg = jQuery( '#js-e-cart-msg' );
            //TODO: Lav set content, header og footer funktioner.
            jCartMsg.find( '.js-e-cart-msg-content' ).html( e4.handlebars.tmpl.cartMsgLastadded( data ) );
            e4.cart.msg.clearTimeout();
            e4.cart.msg.setPosition();
            jCartMsg.modal( 'show' );
            e4.cart.msg.setTimeout();
        },
        hide: function () {
            e4.cart.msg.clearTimeout();
            jQuery( '#js-e-cart-msg' ).modal( 'hide' );
        },
        setPosition: function ( objPosition ) {
            var jCartMsgDialog       = jQuery( '#js-e-cart-msg .modal-dialog' ),
                jHeader              = jQuery( '.js-e-header' ),
                jNavItemCart         = jHeader.find( '.js-e-nav-global,.js-e-nav-utilities-primary' ).find( '.js-e-nav-item-cart' ).filter( ':visible' ),
                objCartMsgPosition   = objPosition || {},
                objReferencePosition = {};
            if ( jCartMsgDialog.length === 1 ) {
                if ( jQuery.isEmptyObject( objCartMsgPosition ) === true ) {
                    if ( jNavItemCart.length === 1 ) {
                        objReferencePosition = jNavItemCart.get( 0 ).getBoundingClientRect();
                        objCartMsgPosition   = {
                            top: jHeader.hasClass( 'is-hidden' ) === true ? '' : Math.floor( objReferencePosition.bottom ),
                            right: Math.floor( jQuery( window ).width() - objReferencePosition.right ),
                            left: '',
                            maxWidth: ''
                        };
                    }
                    else {
                        objReferencePosition = jHeader.get( 0 ).getBoundingClientRect();
                        objCartMsgPosition   = {
                            top: jHeader.hasClass( 'is-hidden' ) === true ? '' : Math.floor( objReferencePosition.bottom ),
                            right: 0,
                            left: 0,
                            maxWidth: '100%'
                        };
                    }
                }
            }
            jCartMsgDialog.css( objCartMsgPosition );
        },
        setTimeout: function () {
            e4.cart.msg.clearTimeout();
            e4.cart.msg.timeout = setTimeout( e4.cart.msg.hide, e4.settings.ecom.cart.msgHideDelay );
        },
        clearTimeout: function () {
            if ( e4.cart.msg.timeout ) {
                e4.cart.msg.timeout = clearTimeout( e4.cart.msg.timeout );
            }
        }
    }
};

