( function () {
    'use strict';
    jQuery( function () {
        e4.forms.init();
        e4.product.init();
        e4.cart.init();
        e4.checkout.init();
        e4.productlist.init();
        e4.header.init();
        e4.nav.init();
    } );
}() );

