e4.login = {
    init: function () {
        e4.login.remember();
    },
    remember: function () {
        jQuery( '.js-e-extranet-login-remember' ).on( 'change', function () {
            jQuery( this.form ).find( '.js-e-extranet-login-remember-username, .js-e-extranet-login-remember-password' ).val( ( jQuery( this ).prop( 'checked' ) === true ? 'True' : '' ) );
        } ).triggerHandler( 'change' );
    }
};

