e4.productlist = {
    jProductList: jQuery( '.js-e-productlist' ),
    init: function () {
        if ( e4.productlist.jProductList.length === 1 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerHelpers( 'compare' );
                //                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-productlist-filters
                // #js-e-handlebars-tmpl-productlist-products #js-e-handlebars-tmpl-productlist-filters-modal
                // #js-e-handlebars-tmpl-productlist-sort #js-e-handlebars-tmpl-productlist-paging' );
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-productlist-filters #js-e-handlebars-tmpl-productlist-products #js-e-handlebars-tmpl-productlist-sort #js-e-handlebars-tmpl-productlist-paging' );
                e4.productlist.bindEvents();
                e4.productlist.history.init();
                e4.productlist.filters.init();
                e4.productlist.modal.init();
            } );
        }
    },
    bindEvents: function () {
        e4.productlist.jProductList.on( 'click', '.js-e-productlist-set-pagesize', function ( event ) {
            event.preventDefault();
            var intPagesize = jQuery( this ).data( 'page-size' );
            e4.productlist.setPagesize( intPagesize );
        } );
        e4.productlist.jProductList.on( 'click', '.js-e-productlist-set-pagenum', function ( event ) {
            event.preventDefault();
            e4.productlist.setPagenum( this );

        } );
        e4.productlist.jProductList.on( 'change', '.js-e-productlist-sort-select', function () {
            e4.productlist.setSort( this );
        } );
        e4.productlist.jProductList.on( 'click', '.js-e-products-item a', function () {
            e4.productlist.history.pushState();
        } );

    },
    filters: {
        init: function () {
            e4.productlist.filters.bindEvents();
            e4.productlist.filters.collapse.init();
            e4.productlist.filters.range.init();
            e4.productlist.filters.search.init();

        },
        bindEvents: function () {
            // TODO: Check om dette virker i IE
            e4.productlist.jProductList.on( 'change', '.js-e-productlist-filter', function () {
                e4.productlist.filters.applyFilters( this );
            } );
            e4.productlist.jProductList.on( 'click', '.js-e-productlist-filters-remove', function ( event ) {
                event.preventDefault();
                e4.productlist.filters.removeFilter( this );
            } );
            jQuery( document ).on( 'click', '.js-e-productlist-filters-clear-all', function ( event ) {
                event.preventDefault();
                e4.productlist.filters.resetAll();
            } );
            e4.productlist.jProductList.on( 'change', '.js-e-productlist-filters-option-reset-group', function ( event ) {
                event.preventDefault();
                if ( jQuery( this ).is( ':checked' ) === true ) {
                    e4.productlist.filters.resetGroup( this );
                }
                else {
                    jQuery( this ).prop( 'checked', true );
                }
            } );

            e4.productlist.jProductList.on( 'click', '.js-e-productlist-filters-options-collapse-peek-toggle', function ( event ) {
                event.preventDefault();
                e4.productlist.filters.collapse.peek.toggle( this );
            } );

            e4.productlist.jProductList.on( 'keydown', '.js-e-productlist-form', function ( event ) {
                if ( event.keyCode === 13 ) {
                    event.preventDefault();
                    e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), e4.productlist.jProductList.offset().top ); //Brug ProductList fra namespace.
                }
            } );

            jQuery( document ).on( 'click', '.js-e-productlist-filters-range-clear', function ( event ) {
                event.preventDefault();
                jQuery( '.js-e-productlist-filters-range-group' ).find( '.js-e-productlist-filters-range' ).val( '' );
                e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), e4.productlist.jProductList.offset().top ); //Brug ProductList fra namespace.
            } );
        },
        refresh: function () {
            e4.productlist.filters.collapse.init();
            e4.productlist.filters.range.validate();
        },
        applyFilters: function ( domElm ) {
            var jThis = jQuery( domElm );
            jThis.closest( '.js-e-productlist-filters-group' ).find( '.js-e-productlist-filters-option-reset-group' ).prop( 'checked', false );
            if ( jThis.is( ':checked' ) === false ) {
                var checkedLength = jThis.closest( '.js-e-productlist-filters-group' ).find( '.js-e-productlist-filter' ).filter( ':checked' ).length;
                if ( checkedLength === 0 ) {
                    jThis.closest( '.js-e-productlist-filters-group' ).find( '.js-e-productlist-filters-option-reset-group' ).prop( 'checked', true );
                }
            }

            jQuery( 'input[name="PageNum"]' ).val( 1 );
            e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
        },
        removeFilter: function ( domElm ) {
            var strValue = jQuery( domElm ).data( 'value' );
            e4.productlist.jProductList.find( '.js-e-productlist-filter[value="' + strValue + '"]' ).prop( 'checked', false );
            e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
        },
        resetGroup: function ( domElm ) {
            jQuery( domElm ).closest( '.js-e-productlist-filters-group' ).find( '.js-e-productlist-filter' ).prop( 'checked', false );
            e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
        },
        resetAll: function () {
            jQuery( 'input[name="PageNum"]' ).val( 1 );
            e4.productlist.jProductList.find( '.js-e-productlist-filter' ).prop( 'checked', false );
            e4.productlist.jProductList.find( '.js-e-search-input' ).val( '' );
            e4.productlist.jProductList.find( '.js-e-productlist-filters-range' ).val( '' );
            e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), 0 );
        },
        collapse: {
            init: function () {
                jQuery( '.js-e-productlist-filters-group-options' ).each( function () {
                    var jThis     = jQuery( this ),
                        jPeekable = jThis.find( '.js-e-productlist-filters-options-collapse-peek' );
                    if ( jThis.is( '.collapse' ) === true ) {
                        jThis.addClass( 'show' );
                    }
                    if ( jPeekable.length === 1 ) {
                        e4.productlist.filters.collapse.peek.setHeight( jPeekable );
                    }
                    if ( jThis.is( '.is-expanded' ) === false ) {
                        jThis.removeClass( 'show' );
                    }
                } );
            },
            peek: {
                setHeight: function ( jPeekable ) {
                    var jFilterOptions        = jPeekable.find( '.js-e-productlist-filters-option' ),
                        intPeekOptionCount    = parseInt( jQuery( '.js-e-visible-options-count' ).val() ) + 1.5,
                        intHeightPeek         = Math.ceil( jFilterOptions.first().outerHeight() ) * intPeekOptionCount,
                        intHeightInitial      = jPeekable.get( 0 ).scrollHeight,
                        intHeightToggleButton = jPeekable.find( '.js-e-productlist-filters-options-collapse-peek-toggle' ).outerHeight();
                    if ( jFilterOptions.filter( '.is-selected' ).length === 0 && jFilterOptions.length > intPeekOptionCount && intHeightInitial > intHeightPeek * e4.settings.collapse.peekSizeMin ) {
                        // console.log( domElm, domElm.scrollHeight );
                        jPeekable.data( {
                            'height-peek': intHeightPeek + intHeightToggleButton,
                            'height-initial': intHeightInitial + intHeightToggleButton
                        } );
                        jPeekable.addClass( 'is-peeking' );
                        jPeekable.css( 'max-height', jPeekable.data( 'height-peek' ) );
                    }
                    else {
                        jPeekable.removeClass( 'e-productlist-filters-options-collapse-peek is-peeking' );
                        jPeekable.find( '.js-e-productlist-filters-options-collapse-peek-toggle' ).remove();
                    }
                },
                toggle: function ( domElm ) {
                    var jPeekable = jQuery( domElm ).closest( '.js-e-productlist-filters-options-collapse-peek' );
                    if ( jPeekable.is( '.is-peeking' ) === true ) {
                        jPeekable.removeClass( 'is-peeking' );
                        jPeekable.css( 'max-height', jPeekable.data( 'height-initial' ) );
                    }
                    else {
                        jPeekable.addClass( 'is-peeking' );
                        jPeekable.css( 'max-height', jPeekable.data( 'height-peek' ) );
                    }
                }
            }
        },
        range: {
            init: function () {
                e4.util.require.js( e4.settings.require.jQueryValidate, function () {
                    jQuery.validator.addMethod( 'rangeValid', function ( value, element, compareInput ) {
                        return parseInt( value ) > parseInt( jQuery( compareInput ).val() );
                    } );
                    e4.productlist.filters.range.bindEvents();
                } );
            },
            bindEvents: function () {
                e4.productlist.jProductList.on( 'click', '.js-e-productlist-filters-apply-range', function () {
                    if ( e4.productlist.jProductList.find( '.js-e-productlist-form' ).valid() === true ) {
                        jQuery( 'input[name="PageNum"]' ).val( 1 );
                        e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
                    }
                } );
            },
            validate: function () {
                e4.productlist.jProductList.find( '.js-e-productlist-form' ).validate( {
                    rules: {
                        PriceRangeMin: {
                            number: true

                        },
                        PriceRangeMax: {
                            number: true,
                            rangeValid: '.js-e-productlist-filters-range-min'
                        }
                    }
                } );
            }
        },
        search: {
            init: function () {
                e4.productlist.filters.search.bindEvents();
            },
            bindEvents: function () {
                e4.productlist.jProductList.on( 'click', '.js-e-productlist-filters-search-submit', function () {
                    e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
                } );
                e4.productlist.jProductList.on( 'click', '.js-e-search-clear', function () {
                    e4.search.clearInput( this );
                    e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
                } );
                e4.productlist.jProductList.on( 'keyup input', '.js-e-search-input', function ( event ) {
                    e4.search.toggleInputGroupState( this, event );
                } );
                e4.productlist.jProductList.on( 'click', '.js-e-productlist-filters-search-clear', function ( event ) {
                    event.preventDefault();
                    // console.log( e4.productlist.jProductList.find( '.js-e-search-input' ).val() );
                    e4.productlist.jProductList.find( '.js-e-search-input' ).val( '' );
                    // console.log( e4.productlist.jProductList.find( '.js-e-search-input' ).val() );
                    e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
                } );
            }
        }
    },
    modal: {
        init: function () {
            e4.productlist.modal.bindEvents();
        },
        bindEvents: function () {
            jQuery( '.js-e-productlist-filters-modal' ).on( 'show.bs.modal', function () {
                e4.util.hash.set( 'isModalFilter', 'true' );
            } ).on( 'hide.bs.modal', function () {
                jQuery( '.js-e-products-loading-overlay' ).addClass( 'is-loading' );
                e4.util.hash.remove( 'isModalFilter' );
                setTimeout( function () {
                    jQuery( '.js-e-products-loading-overlay' ).removeClass( 'is-loading' );
                }, e4.settings.ecom.filters.delay );
            } );
        }
    },
    setPagesize: function ( intPagesize ) {
        e4.productlist.jProductList.find( 'input[name="PageSize"]' ).val( intPagesize );
        e4.productlist.jProductList.find( 'input[name="PageNum"]' ).val( 1 );
        e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
    },
    setPagenum: function ( domElm ) {
        var intPagenum = jQuery( domElm ).data( 'page-num' );
        e4.productlist.jProductList.find( 'input[name="PageNum"]' ).val( intPagenum );
        e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
    },
    setSort: function ( domElm ) {
        var arrSelectSortValue = jQuery( domElm ).val().split( ' ' );
        if ( arrSelectSortValue.length === 2 ) {
            e4.productlist.jProductList.find( 'input[name="Sort"]' ).val( arrSelectSortValue[ 0 ] + ' ' + arrSelectSortValue[ 1 ] );
            e4.productlist.jProductList.find( 'input[name="SortBy"]' ).val( arrSelectSortValue[ 0 ] );
            e4.productlist.jProductList.find( 'input[name="SortOrder"]' ).val( arrSelectSortValue[ 1 ] );
        }
        else {
            e4.productlist.jProductList.find( 'input[name="SortBy"], input[name="SortOrder"]' ).val( '' );
        }
        e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), jQuery( '.js-e-productlist' ).offset().top );
    },
    setScrollTop: function ( intScrollTop ) {
        var jWindow = jQuery( window );
        if ( intScrollTop < jWindow.scrollTop() ) {
            intScrollTop -= jWindow.height() / 100 * e4.settings.scrollToAnchor.nudge;
            e4.scrollToAnchor.animate( intScrollTop, e4.header.hide );
        }
    },
    history: {
        params: null,
        init: function () {
            if ( history.state !== null && history.state.params !== '' ) {
                e4.productlist.data.post( history.state.params, history.state.scrollTop );
            }
            else {
                e4.productlist.data.post( e4.productlist.jProductList.find( '.js-e-productlist-form' ).serialize(), 0 );
            }
            e4.productlist.history.popState();
        },
        pushState: function () {
            var strUrlParam = e4.productlist.history.getParams(),
                stateObj    = {
                    params: e4.productlist.history.params,
                    scrollTop: jQuery( window ).scrollTop()
                };
            if ( jQuery( '#js-e-productlist-filters' ).length === 0 ) {
                e4.settings.ecom.filters.cleanUrl = true;
            }
            history.pushState( stateObj, null, location.pathname + ( e4.settings.ecom.filters.cleanUrl === false ? strUrlParam : '' ) );
        },
        getParams: function () {
            var paramArr = e4.productlist.history.params.split( '&' );
            for ( var i = paramArr.length - 1; i >= 0; i-- ) {
                if ( paramArr[ i ].indexOf( 'PageUrl' ) > -1 ) {
                    paramArr.splice( i, 1 );
                }
            }
            var urlParams = paramArr.join( '&' );
            if ( urlParams.indexOf( '?' ) === -1 ) {
                urlParams = '?' + urlParams;
            }
            return urlParams;
        },
        popState: function () {
            jQuery( window ).on( 'popstate', function ( event ) {
                if ( event.originalEvent.state !== null ) {
                    e4.productlist.data.post( event.originalEvent.state.params, event.originalEvent.state.scrollTop );
                }
            } );
        }
    },
    data: {
        post: function ( strUrlParam, intScrollTop ) {
            jQuery.ajax( {
                type: 'GET',
                url: e4.settings.ecom.filters.url,
                cache: false,
                data: strUrlParam,
                beforeSend: function () {
                    jQuery( '.js-e-products-loading-overlay' ).addClass( 'is-loading' );
                }
            } )
            .done( function ( data ) {
                // console.dir( data );
                e4.productlist.history.params = strUrlParam;
                e4.productlist.data.handler( data );
                e4.productlist.setScrollTop( intScrollTop );
                e4.loading.resolveWait( '.js-e-productlist' );
            } )
            .fail( function ( error ) {
                console.error( error );
            } )
            .always( function () {
                jQuery( '.js-e-products-loading-overlay' ).removeClass( 'is-loading' );
            } );
        },
        handler: function ( data ) {
            var selectedOptionsCount = 0;
            for ( var i = 0; i < data.facets.length; i++ ) {
                selectedOptionsCount += data.facets[ i ].selectedOptions.length;
            }
            if ( data.page.q !== null && data.page.q !== '' ) {
                selectedOptionsCount++;
            }
            if ( ( data.page.minPrice.clean !== null && data.page.minPrice.clean !== '' ) && ( data.page.maxPrice.clean !== null && data.page.maxPrice.clean !== '' ) ) {
                selectedOptionsCount++;
            }
            data.page.filtersAppliedCount = selectedOptionsCount;
            if ( selectedOptionsCount > 0 ) {
                data.page.hasNoInteraction = false;
            }
            if ( e4.util.hash.get( 'isModalFilter' ) === 'true' ) {
                data.page.isModalFilter = true;
            }
            e4.productlist.data.build( data );
        },
        build: function ( data ) {
            e4.productlist.history.pushState();
            var jFilters          = jQuery( '#js-e-productlist-filters' ),
                jFiltersModal     = jQuery( '#js-e-productlist-filters-modal' ),
                jFiltersModalBody = jFiltersModal.find( '#js-e-productlist-filters-modal-body' ),
                jSort             = jQuery( '.js-e-productlist-sort' ),
                jPaging           = jQuery( '.js-e-productlist-paging' );

            if ( jFilters.length === 1 ) {
                var strFiltersHtml = e4.handlebars.tmpl.productlistFilters( data );
                if ( jFilters.is( ':visible' ) === true ) {
                    jFilters.html( strFiltersHtml );
                }
                else {
                    jFiltersModalBody.html( strFiltersHtml );
                }
            }
            if ( jSort.length > 0 ) {
                jSort.html( e4.handlebars.tmpl.productlistSort( data ) );
            }
            if ( jPaging.length > 0 ) {
                jPaging.html( e4.handlebars.tmpl.productlistPaging( data ) );
            }
            e4.productlist.filters.refresh();
            if ( data.page.isModalFilter === true ) {
                jQuery( '.js-e-productlist-filters-modal' ).addClass( 'show' ).modal( 'show' );
            }
            jQuery( '.js-e-productlist-product-count' ).html( data.page.productCount );
            jQuery( '.js-e-productlist-filters-applied-count' ).html( data.page.filtersAppliedCount === 0 ? '' : data.page.filtersAppliedCount );
            jQuery( '#js-e-productlist-products' ).html( e4.handlebars.tmpl.productlistProducts( data ) );
        }
    }
};

