e4.util = {
    init: function () {
        e4.util.catchInternalBackLinks();
        e4.util.catchBlockLinks();
    },
    isInViewport: function ( jElement ) {
        var domElm = jElement.get( 0 ),
            bounds = domElm.getBoundingClientRect();
        return bounds.top < window.innerHeight && bounds.bottom > 0;
    },
    require: {
        //TODO: Lav callback med apply for at understøtte arguments.
        js: function ( script, fnCallback ) {
            if ( eval( script.test ) === undefined ) {
                var jqXHR = jQuery.ajax( {
                    url: script.url,
                    cache: true,
                    timeout: e4.settings.require.timeout,
                    dataType: 'script'
                } );
                jqXHR.done( function () {
                    if ( typeof script.callback === 'function' ) {
                        script.callback();
                        script.callback = null;
                    }
                    if ( typeof fnCallback === 'function' ) {
                        fnCallback();
                    }
                } );
                jqXHR.fail( function () {
                    if ( script.fallback !== null ) {
                        script.url      = script.fallback;
                        script.fallback = null;
                        e4.util.require.js( script, fnCallback );
                    }
                } );
            }
            else {
                if ( typeof fnCallback === 'function' ) {
                    fnCallback();
                }
            }
        }
    },
    registerEvents: function ( arrEvents, strNamespaceLocal ) {
        var objEvents    = {},
            strNamespace = [ e4.settings.event.namespace, strNamespaceLocal ].join( '.' );
        jQuery.each( arrEvents.split( ' ' ), function ( index, element ) {
            //console.log( [ element, strNamespace ].join( '.' ) );
            objEvents[ element ] = [ element, strNamespace ].join( '.' );
        } );
        return objEvents;
    }, //    formatNumberToJavascript: function ( number ) {
    //        // https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-money-in-javascript
    //        return parseFloat( String( number ).replace( new RegExp( '\\' + e4.settings.intergerSeparator, 'g' ), ''
    // ).replace( new RegExp( '\\' + e4.settings.decimalSeparator, 'g' ), '.' ) ); },
    catchInternalBackLinks: function () {
        jQuery( document ).on( 'click', '.js-e-back-link', function ( event ) {
            if ( document.referrer.indexOf( location.protocol + '//' + location.host ) === 0 && history.length !== 1 ) {
                event.preventDefault();
                history.back();
            }
        } );
    },
    catchBlockLinks: function () {
        //        alert('catching');
        jQuery( document ).on( 'click', '.js-e-block-link', function ( event ) {
            var jBlockLink = jQuery( this ).find( 'a[href]' ).first();
            //            jQuery( this ).addClass('bg-primary').append('click');
            //            alert(event.target, event.currentTarget, this);
            if ( jBlockLink.length === 1 && jQuery( event.target ).closest( 'a[href]' ).length === 0 && /input|textarea/i.test( event.target.tagName ) === false ) {
                //                alert('taking off...!');
                if ( jBlockLink.attr( 'target' ) === '_blank' ) {
                    window.open( jBlockLink.attr( 'href' ) );
                }
                else {
                    location.href = jBlockLink.attr( 'href' );
                }
            }
        } );
    },
    getBreakPoint: function () {
        var intWindowWidth = Math.max( jQuery( window ).width(), window.innerWidth );
        if ( intWindowWidth >= e4.settings.breakpoint.xs && intWindowWidth < e4.settings.breakpoint.sm ) {
            return 'xs';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.sm && intWindowWidth < e4.settings.breakpoint.md ) {
            return 'sm';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.md && intWindowWidth < e4.settings.breakpoint.lg ) {
            return 'md';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.lg && intWindowWidth < e4.settings.breakpoint.xl ) {
            return 'lg';
        }
        else if ( intWindowWidth >= e4.settings.breakpoint.xl ) {
            return 'xl';
        }
        return null;
    },
    hash: {
        get: function ( key ) {
            var returnValue,
                arrHashes = [];
            if ( location.hash.indexOf( '#/' ) === 0 && location.hash.lastIndexOf( '/' ) === location.hash.length - 1 ) {
                arrHashes = location.hash.substring( 2, location.hash.length - 1 ).split( '&' );
            }
            if ( key === undefined ) {
                returnValue = {};
            }
            else {
                key = e4.util.hash.decode.key( e4.util.hash.encode.key( key ) );
            }
            for ( var i = 0; i < arrHashes.length; i++ ) {
                try {
                    var arrHashKeyValue = arrHashes[ i ].split( '=' ),
                        hashKey         = e4.util.hash.decode.key( arrHashKeyValue[ 0 ] ),
                        hashValue       = e4.util.hash.decode.value( arrHashKeyValue.slice( 1 ).join( '=' ) );
                    if ( key === hashKey ) {
                        returnValue = hashValue;
                        break;
                    }
                    else if ( key === undefined ) {
                        returnValue[ hashKey ] = hashValue;
                    }
                }
                catch ( e ) {
                }
            }
            return returnValue;
        },
        set: function ( key, value ) {
            var arrHashes = e4.util.hash.buildNewHashArray( key, value ),
                strHash   = e4.util.hash.buildNewHashString( arrHashes );
            //console.log( location.hash, e4.util.hash.getCurrentHashStringValue(), strHash, arrHashes );
            if ( e4.util.hash.getCurrentHashStringValue() !== strHash ) {
                location.hash = strHash;
                //console.log( 'hash changed - set' );
            }
        },
        replace: function ( key, value ) {
            var arrHashes      = e4.util.hash.buildNewHashArray( key, value ),
                strHashNew     = e4.util.hash.buildNewHashString( arrHashes ),
                strHashCurrent = e4.util.hash.getCurrentHashStringValue(),
                strURL         = location.href;
            //console.log( location.hash, e4.util.hash.getCurrentHashStringValue(), strHashNew, arrHashes );
            if ( strHashCurrent !== strHashNew ) {
                if ( location.href.indexOf( '#' ) === -1 ) {
                    strURL = strURL + '#' + strHashNew;
                }
                else {
                    strURL = strURL.replace( '#' + strHashCurrent, '#' + strHashNew );
                }
                window.history.replaceState( null, '', strURL );
                jQuery( window ).triggerHandler( 'hashchange' );
                //console.log( 'hash changed - replace' );
            }
        },
        remove: function ( key ) {
            e4.util.hash.set( key, null );
        },
        buildNewHashArray: function ( key, value ) {
            var arrHashes     = [],
                hashKeyExists = false;
            key               = e4.util.hash.encode.key( key );
            jQuery.each( e4.util.hash.get(), function ( hashKey, hashValue ) {
                hashKey   = e4.util.hash.encode.key( hashKey );
                hashValue = e4.util.hash.encode.value( hashValue );
                if ( hashKey === key ) {
                    hashKeyExists = true;
                    if ( value !== null ) {
                        hashValue = e4.util.hash.encode.value( value );
                        arrHashes.push( hashKey + '=' + hashValue );
                    }
                }
                else {
                    arrHashes.push( hashKey + '=' + hashValue );
                }
            } );
            if ( hashKeyExists === false && value !== null ) {
                arrHashes.push( key + '=' + e4.util.hash.encode.value( value ) );
            }
            return arrHashes;
        },
        buildNewHashString: function ( arrHashes ) {
            var strHash = '';
            if ( arrHashes.length !== 0 ) {
                strHash = '/' + arrHashes.join( '&' ) + '/';
            }
            return strHash;
        },
        getCurrentHashStringValue: function () {
            return location.hash.replace( /^#/, '' );
        },
        encode: {
            key: function ( key ) {
                return encodeURIComponent( String( key ).replace( /\s/g, '_' ) );
            },
            value: function ( value ) {
                if ( String( value ).charAt( 0 ) === '{' || typeof value === 'object' ) {
                    try {
                        value = JSON.stringify( value );
                    }
                    catch ( e ) {
                    }
                }
                return value === undefined ? '' : encodeURIComponent( value );
            }
        },
        decode: {
            key: function ( key ) {
                return decodeURIComponent( key );
            },
            value: function ( value ) {
                value = decodeURIComponent( value );
                if ( value.charAt( 0 ) === '{' ) {
                    try {
                        value = JSON.parse( value );
                    }
                    catch ( e ) {
                    }
                }
                return value;
            }
        }
    }
};

