e4.p.banner = {
    init: function () {
        e4.p.banner.loadImages();
        e4.p.banner.bindEvents();
        e4.p.banner.video.init();
    },
    loadImages: function () {
        jQuery( '.js-p-banner-animation-image' ).each( function () {
            jQuery( this ).css( 'background-image', 'url(' + this.getAttribute( 'data-src' ) + ')' );
        } ).filter( ':first-child' ).addClass( 'animate-in' );
    },
    video: {
        init: function () {
            var jBannerVideo = jQuery( '.js-p-banner-video' );
            if ( jBannerVideo.length > 0 ) {
                e4.util.require.js( e4.settings.require.objectFitPolyfill, function () {
                    var intWindowWidth = jQuery( window ).width();
                    jBannerVideo.each( function ( intIndex, domNode ) {
                        var jVideo = jQuery( domNode ).find( 'video' );
                        if ( jVideo.data( 'autoplayOnPhone' ) === false && intWindowWidth < e4.settings.breakpoint.lg ) {
                            jVideo.trigger( 'pause' );
                        }
                        else {
                            jVideo.trigger( 'play' );
                        }
                    } );
                } );
            }
        }
    }, 
    bindEvents: function () {
        jQuery( '.js-p-banner-animation-image-container' ).on( e4.settings.event.animationend, '.js-p-banner-animation-image', function () {
            e4.p.banner.animate( this );
        } ).on( e4.settings.event.transitionend, '.js-p-banner-animation-image.animate-out', function () {
            jQuery( this ).removeClass( 'animate-out' );
        } );
        jQuery( '.js-p-banner-next-section-link' ).on( 'click', function ( event ) {
            event.preventDefault();
            e4.p.banner.scrollToNextSection( this );
        } );
    },
    scrollToNextSection: function ( domElm ) {
        var intNextSectionOffset = jQuery( domElm ).closest( '.e-section' ).next( '.e-section' ).offset().top;
        e4.scrollToAnchor.animate( intNextSectionOffset );
    },
    animate: function ( domElm ) {
        var jImageActive = jQuery( domElm ),
            jImageNext = jImageActive.next();
        if ( jImageNext.length === 0 ) {
            jImageNext = jImageActive.closest( '.js-p-banner-animation-image-container' ).find( '.js-p-banner-animation-image' ).first();
        }
        jImageNext.addClass( 'animate-in' );
        jImageActive.removeClass( 'animate-in' ).addClass( 'animate-out' );
    }
};

